@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
:root{
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #2978b5;
  --font-primary: 'Poppins', sans-serif;
}
.light{
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #2978b5;
  --shadow: rgba(100,100,111,0.2) 0px 7px 29px 0px;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  --font-primary: 'Poppins', sans-serif;
}

h1{
  font-size:1.5rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
}
h2{
  font-size:1rem;
  margin-top: .5rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
}
li{
  font-size: 1rem !important;
  font-family: "Poppins", sans-serif !important;
  color: var(--clr-fg-alt) !important;
  margin-left: 1rem !important;
}

li > ul > li{
  
  list-style-type: disc;

}

img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 10px;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-center{
  display:flex;
  justify-content: space-between;
  align-items: center;
  height: 8em;
  max-width: 576px;
  width: 80%;
  margin: 0 auto;
}
.header-center h3{
  font-size: 1.5rem;
  color: var(--clr-primary);
  font-family: var(--font-primary);
  font-weight: bold;
}
.center-nav{
  display:flex;
  align-items: center;
}
.nav-list{
  display:flex;
  list-style:none;
}

.nav-list li{
  margin-left: 1rem;

}

.nav-list li a{
  text-decoration: none;
  color: var(--clr-fg);
  font-family: var(--font-primary);
  line-height: 1.5rem;
  font-weight: 500;
  font-size: 1rem;
}
.about{
  flex-direction: column;
  margin-top: 2rem;
}
.about-name{
  color: var(--clr-primary);
}

.about-location img{
  width: 20px;
  height: 20px;
  
}

.about-location{
  margin-top: 1.5rem;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  
}
.about-me{
  margin-top: 1.5rem;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  width: 300px; 
}
.about-me-melbourne{
  margin-top: .5rem;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  width: 300px; 
}
.about-tech-used-heading{
  margin-top: 1.5rem;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  text-transform: capitalize;
}
.tech-list{
  display: grid;
  max-width: 200px;
  
}
.tech-list-item{
  list-style-type: none;
  margin: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  
}
.backdrop{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.modal{
  width: 300px;
  margin: 0 auto;
  padding: 20px 20px;
  background: white;
  border-radius: 10px;
  text-align: center;
}
.modal-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.modal-tech-title{
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: var(--clr-fg-alt);
  text-transform: capitalize;
}

.close-modal-btn{
  font-size: 1.5rem;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
}
.about-grid{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  max-width: 300px;
  margin: 0 auto 6rem;
 
}

.left-grid-item{
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  justify-content: center;

}
.right-grid-item{
  display: flex;
  flex-direction: column;
}

.timeline-title{
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-primary);
  text-transform: capitalize;
}
.timeline-paragraph{
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  text-transform: capitalize;
  margin-top: .5rem;
}


.timeline-item-title-left{
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  text-transform: capitalize;
}
.testimonial-paragraph-left{
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  text-transform: capitalize;
  
}
.social-links-left{
  display: flex;
}
.social-link-item{
  list-style: none;
}

.social-link-item a{
  text-decoration: none;
  color: var(--clr-primary);
  font-size: 1.5rem;
  margin-right: 1rem;
}
.social-link-item a:hover{
  color: var(--clr-fg-alt);
}
.testimonial-img{
  width: 150px;
  border-radius: 50%;
  background: #2978b5;
  margin-top: 1rem;
}
.hr{
  height: 2px;
  background: var(--clr-primary);
  border: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.timeline-item-title{
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  text-transform: capitalize;
  margin-top: .5rem;
}
.timeline-item-sub-title{
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  text-transform: capitalize;
}

.timeline-item-info-list{
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  list-style-type: disc;
  color: var(--clr-primary);
  font-family: "Poppins", sans-serif;
}
.skills-list{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  max-width: 300px;
}
.skills-list-item{
  list-style-type: none;
  margin-right: 1rem;
  margin-top: 1rem;
}
.skills-list-item img{
  width: 48px;
  height: 48px;
}
.pictures-list-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.pictures-list-item img{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width:300px;
  height: 200px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius:10px ;
  cursor: zoom-in;
}

.pictures-list-item p{
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  text-transform: capitalize;
  margin-left : 1rem;
  font-weight: bold;
}

.post-container{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 330px;
}
.posts{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 8em;
  max-width: 400px;
  width: 80%;
  margin: 0 auto;
}
.posts a{
  text-decoration: none;
  margin-top: 1rem;
  width: 100%;
  height: 100%;
}
.posts-item{
  display: flex;
  flex-direction: column;
}
.posts-item h1{
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  text-transform: capitalize;
  font-weight: bold;
}
.posts-item a> small{
  margin-right: 5px;
}
.posts-item h1:hover{
  cursor: pointer;
}
.modal-body{
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--clr-fg-alt);
  text-transform: capitalize;
}
@media screen and (min-width: 576px) {
  h1{
    font-size:3rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
  }
  .center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-center{
    display:flex;
    justify-content: space-between;
    align-items: center;
    height: 8em;
    max-width: 1100px;
    width: 80%;
    margin: 0 auto;
  }
  .header-center h3{
    font-size: 1.5rem;
    color: var(--clr-primary);
    font-family: var(--font-primary);
    font-weight: bold;
  }
  .center-nav{
    display:flex;
    align-items: center;
  }
  .nav-list{
    display:flex;
    list-style:none;
  }
  
  .nav-list li{
    margin-left: 1rem;
  
  }
  
  .nav-list li a{
    text-decoration: none;
    color: var(--clr-fg);
    font-family: var(--font-primary);
    line-height: 1.5rem;
    font-weight: 500;
    font-size: 1rem;
  }
  .about{
    flex-direction: column;
    margin-top: 3rem;
  }
  .about-name{
    color: var(--clr-primary);
  }
  
  .about-location img{
    width: 30px;
    height: 30px;
    
  }
  
  .about-location{
    margin-top: 1.5rem;
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    
  }
  .about-me{
    margin-top: 1.5rem;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    width: 400px; 
  }
  .about-me-melbourne{
    margin-top: .5rem;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    width: 400px; 
  }
  .about-tech-used-heading{
    margin-top: 1.5rem;
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
  }
  .tech-list{
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
  }
  .tech-list-item{
    list-style-type: none;
    margin: 1rem;
    font-size: 1.5rem;
    
  }
  .backdrop{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .modal{
    width: 400px;
    margin: 0 auto;
    padding: 20px 20px;
    background: white;
    border-radius: 10px;
    text-align: center;
  }
  .modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .modal-tech-title{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
  }
  
  .close-modal-btn{
    font-size: 1.5rem;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
  }

  .pictures-list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .pictures-list-item img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    width:400px;
    height: 300px;
    aspect-ratio: 4/3;
    object-fit: cover;
    border-radius:10px ;
  }
  
  .pictures-list-item p{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
    margin-left : 1rem;
    font-weight: bold;
  }

  .resume-span{
    
    text-decoration: none;
    background: transparent;
    border: none;
    color: var(--clr-primary);
    font-family: var(--font-primary);
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    
  }
  
}


@media screen and (min-width: 768px){
  h1{
    font-size:4rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
  }
  .center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
    header-center{
    display:flex;
    justify-content: space-between;
    align-items: center;
    height: 8em;
    max-width: 768px;
    width: 95%;
    margin: 0 auto;
  }
  .header-center h3{
    font-size: 1.5rem;
    color: var(--clr-primary);
    font-family: var(--font-primary);
    font-weight: bold;

  }
  .center-nav{
    display:flex;
    align-items: center;
  }
  .nav-list{
    display:flex;
    list-style:none;
  }
  
  .nav-list li{
    margin-left: 1rem;
  
  }
  
  .nav-list li a{
    text-decoration: none;
    color: var(--clr-fg);
    font-family: var(--font-primary);
    line-height: 1.5rem;
    font-weight: 500;
    font-size: 1rem;
  }
  
  .about{
    flex-direction: column;
    margin-top: 1rem;
  }
  
  .about-name{
    color: var(--clr-primary);
  }
  
  .about-location img{
    width: 40px;
    height: 40px;
    
  }
  
  .about-location{
    margin-top: 2rem;
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
  }
  
  .about-me{
    margin-top: 2rem;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    width: 500px; 
  }
  .about-me-melbourne{
    margin-top: .5rem;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    width: 500px; 
  }
  .about-tech-used-heading{
    margin-top: 2rem;
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
  }

  .about-tech-used-info{
    margin-top: 1rem;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    width: 500px; 
  }

  .tech-list{
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
  }
  .tech-list-item{
    list-style-type: none;
    margin: 1rem;
    font-size: 1.5rem;
    
  }
  .backdrop{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .modal{
    width: 400px;
    margin: 0 auto;
    padding: 20px 20px;
    background: white;
    border-radius: 10px;
    text-align: center;
  }
  .modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .modal-tech-title{
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
  }
  .modal-body{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
  }

  .close-modal-btn{
    font-size: 2rem;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
  }

  .pictures-list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .pictures-list-item img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    width:640px;
    height: 480px;
    aspect-ratio: 4/3;
    object-fit: cover;
    border-radius:10px ;
  }

  .pictures-list-item p{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
    margin-left : 1rem;
    font-weight: bold;
  }

  .post-container{
    display: flex;
    flex-direction: column;
    max-width: 768px;
    margin: 0 auto;
  }

  .post-container p{
    margin-bottom: .5rem;
  }

  .post-container h1::after{
    content: "";
    display: block;
    height: 1rem;
  }

  .posts{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    height: 8em;
    max-width: 768px;
    width: 95%;
    margin: 0 auto;
  }

  .posts a{
    text-decoration: none;
    margin-top: 1rem;
    width: 100%;
    height: 100%;
  }
  .posts-item{
    display: flex;
    flex-direction: column;
  }
  .posts-item h1{
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
    font-weight: bold;
  }
  .posts-item a> small{
    margin-right: 5px;
  }
  .posts-item h1:hover{
    cursor: pointer;
  }

  .post-content{
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    color: var(--clr-fg-alt);
  }

}
  
@media screen and (min-width: 530px) {
  .about-grid{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    max-width: 450px;
    margin: 0 auto 6rem;
    margin-bottom: 6rem;
  }
  
  .left-grid-item{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
  
  }
  .right-grid-item{
    display: flex;
    flex-direction: column;
  }
  
  .timeline-title{
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-primary);
    text-transform: capitalize;
  }
  .timeline-paragraph{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
    margin-top: .5rem;
  }
  .timeline-item-title-left{
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
  }
  .testimonial-paragraph-left{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
    
  }
  .social-links-left{
    display: flex;
  }
  .social-link-item{
    list-style: none;
  }
  
  .social-link-item a{
    text-decoration: none;
    color: var(--clr-primary);
    font-size: 2rem;
    margin-right: 1rem;
  }
  .social-link-item a:hover{
    color: var(--clr-fg-alt);
  }
  .testimonial-img{
    width: 200px;
    border-radius: 50%;
    background: #2978b5;
    margin-top: 1rem;
  }
  .hr{
    height: 2px;
    background: var(--clr-primary);
    border: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .timeline-item-title{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
    margin-top: .5rem;
  }
  .timeline-item-sub-title{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
  }
  
  .timeline-item-info-list{
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    list-style-type: disc;
    color: var(--clr-primary);
  }
  .skills-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    max-width: 300px;
  }
  .skills-list-item{
    list-style-type: none;
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .skills-list-item img{
    width: 48px;
    height: 48px;
  }
}
/*Responsiveness set only for the About page elements*/
@media screen and (min-width: 830px){
  .about-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    max-width: 768px;
    margin: 0 auto 6rem;
    margin-bottom: 6rem;
  }

  .left-grid-item{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    position: sticky;
    top: 0;
  }
  .right-grid-item{
    display: flex;
    flex-direction: column;
  }


  .timeline-title{
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-primary);
    text-transform: capitalize;
  }
  .timeline-paragraph{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
    margin-top: .5rem;
  }
  .timeline-item-title-left{
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
  }
  .timeline-flex{
    display: flex;
    flex-direction: column;
  }

  .timeline-item-title{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
    margin-top: .5rem;
  }
  .timeline-item-sub-title{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
  }

  .timeline-item-info-list{
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    list-style-type: disc;
    color: var(--clr-primary);
  }

  .highlight-word{
    color: var(--clr-primary);
  }

  .hr{
    height: 2px;
    background: var(--clr-primary);
    border: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .timeline-item-info-list-item{
    font-size: .9rem;
    font-family: "Poppins", sans-serif;
   
  }

  .testimonial-paragraph{
    font-size: .9rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
    margin-top: .5rem;
  }

  .testimonial-paragraph-left{
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    color: var(--clr-fg-alt);
    text-transform: capitalize;
  }

  .social-links-left{
    display: flex;

  }
  .social-link-item{
    list-style: none;
  }

  .social-link-item a{
    text-decoration: none;
    color: var(--clr-primary);
    font-size: 2rem;
    margin-right: 1rem;
  }
  .social-link-item a:hover{
    color: var(--clr-fg-alt);
  }
  .skills-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    max-width: 300px;
  }
  .skills-list-item{
    list-style-type: none;
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .skills-list-item img{
    width: 48px;
    height: 48px;
  }
  .testimonial-img{
    width: 200px;
    border-radius: 50%;
    background: #2978b5;
    margin-top: 1rem;
  }

}